import React from "react";

const Text = ({ content }) => {
  return (
    <div className={"text-bigForms"}>
      {content.split("\n").map((item, i) => (
        <React.Fragment key={i}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export { Text };
